<template>
  <div id="footer">
    <div class="foot_pc">
      <div class="footer_box">
        <div class="footer_box_info">
          <div class="footer_logo"><img :src="logo" /></div>
          <div class="footer_info">
            <div>
              <span>地址：广州市天河区黄埔大道东582号金岸创艺园1号楼</span>
              <span>电话：18024097868</span>
              <span>网址：http://www.newmountgame.com</span>
            </div>
            <div class="footer_icp">© 2023 广州新游泰动互联网科技有限公司 <span @click="openNewWindow">粤ICP备2023049516号</span></div>
          </div>
        </div>

      </div>
    </div>
    <div class="foot_h5">
      <div>地址：广州市天河区黄埔大道东582号金岸创艺园1号楼</div>
      <div>电话：18024097868</div>
      <div>网址：http://www.newmountgame.com</div>
      <div class="footer_icp">© 2023 广州新游泰动互联网科技有限公司 <span @click="openNewWindow">粤ICP备2023049516号</span>
      </div>
    </div>
  </div>
</template>

<script>
import logo from '@/assets/logo.gif';
export default {
  data () {
    return {
      logo
    }
  },
  methods: {
    openNewWindow () {

      window.open('http://beian.miit.gov.cn', '_blank');
    }
  },
}
</script>

<style lang="less" scoped>
#footer {
  background-color: #000;
  color: hsla(0, 0%, 100%, 0.4);
  font-size: 12px;
  padding: 1% 0;

  .footer_box {
    width: 1200px;
    margin: auto;

    .footer_box_info {
      display: flex;
      vertical-align: middle;

      .footer_info {
        width: 100%;
        line-height: 40px;

        div span {
          margin-right: 10%;
        }
      }

      .footer_logo {
        width: 244px;
        text-align: center;

        img {
          height: 80px;
        }
      }
    }
  }

  .foot_h5 {
    line-height: 2em;
  }

  @media screen and (min-width: 1200px) {
    .foot_pc {
      display: block;
    }

    .foot_h5 {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .foot_pc {
      display: none;
    }

    .foot_h5 {
      display: block;
    }
  }
}
</style>
