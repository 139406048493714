<template>
  <div id="header">
    <div :class="['headerWrap',fill||$route.path=='/product'?'fill':'']">
      <div class="headerWrapBox">
        <div class="logo" @click="toLink('/')">
          <img src="../../assets/logo.png" />
        </div>
        <div class="menu">
          <div :class="['menu_item',active==route.path?'active':'']" v-for="route in routes" :key="route.path" @click="toLink(route.path)">{{ route.name }}</div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {

  data () {
    return {
      routes: [],
      active: '',
      fill: false
    }
  },
  created () {
    this.routes = this.$router.getRoutes().filter(item => { return item.path });
  },
  watch: {
    $route (newValue) {
      this.active = newValue.path
    }
  },
  mounted () {
    window.addEventListener('scroll', () => {
      // 获取滚动高度
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if (scrollTop > 0) {
        this.fill = true
      } else {
        this.fill = false
      }
    });
    setTimeout(() => {
      this.active = this.$router.currentRoute.path;
    }, 200);
  },

  methods: {
    toLink (path) {

      if (this.$route.path == path) {
        return
      }
      this.active = path,
        this.$router.push({
          path
        })
    }
  },
}
</script>

<style lang="less" scoped>
#header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 84px;
  .active {
    color: #fc0 !important;
  }
  .active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 18px;
    width: 38px;
    height: 2px;
    background-color: #fc0;
  }
  .headerWrap.fill {
    color: #353535 !important;
    background-color: #fff;
    border-color: #e0e0e0;

    box-shadow: 0 2px 8px #888;
    .menu_item {
      color: #353535 !important;
    }
  }
  .headerWrap {
    display: flex;
    transition: 0.5s ease;
    height: 100%;
    .headerWrapBox {
      width: 1080px;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
    }
    .logo {
      float: left;
      height: 100%;
      img {
        height: 70%;
        width: auto;
        margin: 15% auto;
      }
    }
    @media screen and (min-width: 1200px) {
      .logo {
        display: block;
      }
      .menu_item {
        margin-left: 40px;
      }
    }
    @media screen and (max-width: 1200px) {
      .logo {
        display: none;
      }
      .menu_item {
        margin-left: 30px;
        margin-right: 10px;
      }
    }
    .menu {
      float: right;
      .menu_item {
        position: relative;
        line-height: 83px;
        font-size: 16px;
        text-decoration: none;
        color: #fff;
        float: left;
        cursor: pointer;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .headerWrap {
      color: #353535 !important;
      background-color: #fff;
      border-color: #e0e0e0;
      .menu_item {
        color: #353535 !important;
      }
    }
  }
}
</style>
