<template>
  <div id="index">
    <div class="home_bg">
      <img src="../assets/home.png" />
    </div>
    <div class="pl" >
		<div class="pl_item"> <img src="../assets/pl_xyx.png"/><div><span>小游戏类</span></div></div>
		<div class="pl_item"> <img src="../assets/pl_kl.jpg" /><div><span>模拟恐龙类</span></div></div>
		<div class="pl_item"> <img src="../assets/pl_pk.jpg" /><div><span>跑酷类</span></div></div>
		<div class="pl_item"> <img src="../assets/pl_qjz.jpg"/><div><span>轻度竞技类</span></div></div>
		<div class="pl_item"> <img src="../assets/pl_xx.jpg" /><div><span>休闲类</span></div></div>
		
      <!-- <div class="banner_a">
        <div class="banner_portrait">
          <img src="../assets/pl_kl.jpg" />
        </div>
        <div class="describe">
          <div class="til">文字找茬大师</div>
          <div class="banner_des_line"></div>
          <div class="des">
            <p>古风题材UI，拥有各种主题时下热点的脑洞解谜游戏。</p>
          </div>
        </div>
      </div>
      <div class="banner_b">

        <div class="describe">
          <div class="til">诡异找茬</div>
          <div style="text-align: right;">
            <span class="banner_des_line">
            </span>
          </div>
          <div class="des">
            <p>轻松简单的玩法，全新互动式解谜，让你沉浸于诡异的恐怖关卡，胆小勿进！</p>
          </div>
        </div>
        <div class="banner_portrait">
          <img src="../assets/pl_pk.jpg" />
        </div>
      </div> <div class="banner_a">
        <div class="banner_portrait">
          <img src="../assets/pl_qjz.jpg" />
        </div>
        <div class="describe">
          <div class="til">文字找茬大师</div>
          <div class="banner_des_line"></div>
          <div class="des">
            <p>古风题材UI，拥有各种主题时下热点的脑洞解谜游戏。</p>
          </div>
        </div>
      </div>
      <div class="banner_b">

        <div class="describe">
          <div class="til">诡异找茬</div>
          <div style="text-align: right;">
            <span class="banner_des_line">
            </span>
          </div>
          <div class="des">
            <p>轻松简单的玩法，全新互动式解谜，让你沉浸于诡异的恐怖关卡，胆小勿进！</p>
          </div>
        </div>
        <div class="banner_portrait">
          <img src="../assets/pl_xx.jpg" />
        </div>
      </div> -->
    </div>

    <productMount></productMount>
    <aboutNount></aboutNount>
    <joinMount></joinMount>

  </div>
</template>

<script>
import productMount from './homeSection/productMount.vue';
import aboutNount from './homeSection/aboutNount.vue';
import joinMount from './homeSection/joinMount.vue';
export default {
  name: "HomeView",
  components: {
    aboutNount, joinMount, productMount
  },
  data () {
    return {

    }
  },

  methods: {
    toLink (path) {
      this.$router.push({
        path
      })
    }
  },
};
</script>
<style lang="less" scoped>
.home_bg {
  width: 100%;
  img {
    width: 100%;
  }
}
.pl{
	text-align: center;
	width: 100%;
  background-color: #fff;
  overflow: hidden;
 display: flex;
 justify-items:center;
 align-items: baseline;
 margin: 30px auto;
    justify-content: center;
	.pl_item{
		padding: 1%;
		box-sizing: border-box;
		img{
			width: 100%;
			margin-bottom: 10%;
		}
		span{
			padding: 8px 16px;
			background: #fede69;
			color:#f05822 ;
			border-radius: 20px;
			
		}
		
	}
	.pl_item:nth-child(2n){
		span{
			background: #f05822;
			color: #fede69;
		}
	}

	@media screen and (max-width: 1200px) {
		.pl_item span{
			font-size: 12px;
			padding: 4px;
		}
	}
}
// .banner {
//   width: 100%;
//   background-color: #fff;
//   overflow: hidden;
//   img{
// 	padding: 10px;
//   }
//   .banner_portrait {
//     text-align: center;
//     img {
//       width: 90%;
//     }
//   }
//   .til {
//     color: #000;
//     margin-top: 4px;
//     margin-bottom: 26px;
//   }
//   .des {
//     line-height: 26px;
//     font-size: 14px;
//     color: rgba(0, 0, 0, 0.4);
//   }
//   .banner_des_line {
//     width: 58px;
//     height: 4px;
//     background-color: #fc0;
//   }
//   .banner_b {
//     margin: 34px auto 0;

//     .describe {
//       box-sizing: border-box;
//     }
//     @media screen and (max-width: 1200px) {
//       display: block;
//       width: 100%;
//       .describe {
//         width: 100%;
//         padding-right: 0;
//       }
//       .banner_portrait {
//         width: 100%;
//       }
//       .til {
//         text-align: center;
//       }
//       .des {
//         text-align: center;
//       }
//       .banner_des_line {
//         display: none;
//       }
//     }

//     @media screen and (min-width: 1200px) {
//       display: flex;
//       max-width: 1200px;
//       .describe {
//         width: 632px;
//         padding-right: 122px;
//       }
//       .banner_portrait {
//         width: 568px;
//       }
//       .til {
//         text-align: right;
//       }
//       .des {
//         text-align: right;
//       }
//       .banner_des_line {
//         display: inline-block;
//       }
//     }
//   }
//   .banner_a {
//     max-width: 1200px;
//     @media screen and (max-width: 1200px) {
//       margin: 34px auto 0;
//       display: block;
//       width: 100%;
//       .describe {
//         width: 100%;
//         padding-right: 0;
//       }
//       .banner_portrait {
//         width: 100%;
//       }
//       .til {
//         text-align: center;
//       }
//       .des {
//         text-align: center;
//       }
//       .banner_des_line {
//         display: none;
//       }
//     }
//     @media screen and (min-width: 1200px) {
//       margin: 74px auto 0;
//       display: flex;
//       max-width: 1200px;
//       .describe {
//         width: 632px;
//         padding-left: 122px;
//       }
//       .banner_portrait {
//         width: 568px;
//       }
//       .til {
//         text-align: left;
//       }
//       .des {
//         text-align: left;
//       }
//       .banner_des_line {
//         display: block;
//       }
//     }

//     .describe {
//       box-sizing: border-box;
//       height: 100%;
//     }
//   }
// }
</style>
