<template>
  <div class="section aboutUs">
    <div class="section_wrapper">
      <div v-animate-onscroll.repeat="'animate__animated  animate__lightSpeedInRight'" class="portrait"><img :src="aboutUsJpg" /></div>
      <div class="describe">
        <div class="til">关于 MONTSPACE</div>
        <div class="des">
          <p>我们是一群带着干劲与冲劲的团队。</p>
        </div>
        <div class="btn" @click="toLink('about')">了解更多 ></div>
      </div>
    </div>
  </div>

</template>

<script>
import aboutUsJpg from '@/assets/aboutUs.jpg';
export default {
  data () {
    return {
      aboutUsJpg
    }
  },
  methods: {
    toLink (path) {
      this.$router.push({
        path
      })
    }
  },
}
</script>

<style lang="less">
.aboutUs {
  background: #ececec;

  .describe {
    position: absolute;
    top: 128px;
    left: 0;
  }
  @media screen and (max-width: 1200px) {
    background: url("../../assets/aboutUs.png");
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 360px 100%;

    .describe {
      width: 100%;
      top: 40px;
    }
    .til {
      text-align: center;
    }
    .portrait img {
      display: none;
    }
  }
  @media screen and (min-width: 1200px) {
    .describe {
      width: 474px;
    }
    .til {
      font-size: 34px;
      color: #000;
      text-align: right;
      padding-right: 6%;
    }
    .btn {
      margin-left: 70%;
    }
    .des {
      margin-bottom: 50px;
    }
    .portrait {
      position: absolute;
      width: 716px;
      height: 392px;
      left: 600px;
      bottom: 0;
      img {
        height: 100%;
        width: auto;
        display: block;
      }
    }
  }
}
</style>
