<template>
  <div id="app">
    <montHead></montHead>
    <div id="container"><router-view></router-view></div>
    <montFoot></montFoot>
  </div>

</template>
<script>
import montHead from '@/components/layout/montHead.vue';
import montFoot from '@/components/layout/montFoot.vue';
export default {
  components: {
    montHead, montFoot,
  },
}
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

body::-webkit-scrollbar-thumb {
  background-color: #c1c1c1;
}
body::-webkit-scrollbar-thumb:hover {
  background-color: #a8a8a8;
}

body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #5c5c5c;
  box-sizing: border-box;
}
.section_wrapper {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.btn {
  cursor: pointer;
  margin: auto;
}

@media screen and (max-width: 1200px) {
  .home_bg,
  .head_banner {
    padding-top: 84px;
    .til {
      padding-top: 26% !important;
    }
  }

  .til {
    font-size: 18px;
    color: #000;
    font-weight: normal;
  }
  .des {
    font-size: 12px;
    color: #000;
    margin: 20px auto 24px;
  }
  .btn {
    width: 80px;
    background: #fff;
    line-height: 30px;
    color: #000;
    font-size: 12px;
    border-radius: 15px;
  }
  .section {
    width: 100%;
    height: 203px;
  }
}

@media screen and (min-width: 1200px) {
  .til {
    font-size: 38px;
    font-weight: 600;
  }
  .des {
    font-size: 20px;
    margin: 30px auto 86px;
    color: rgba(0, 0, 0, 0.4);
  }
  .btn {
    width: 116px;
    line-height: 34px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
    border: 2px solid rgba(0, 0, 0, 0.4);
  }
  .section {
    width: 100%;
    height: 400px;
  }
}
</style>
