import Vue from "vue";
import VueRouter from "vue-router";
import homePage from "../views/homePage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "首页",
    component: homePage,
  },
  {
    path: "/product",
	name: "产品信息",
    component: () => import("../views/productPage.vue"),
  },
  {
    path: "/about",
	name: "关于我们",
   
    component: () => import("../views/aboutUs.vue"),
  },
  {
    path: "/join",
	name: "加入我们",
    component: () => import("../views/joinUs.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
