<template>
  <div class="section joinUs">
    <div class="section_wrapper">
      <div class="describe">
        <div class="til">加入 MONTSPACE</div>
        <div class="des">
          <p>一起踏上伟大的冒险之旅吧，</p><p>和我们一起做大做强。</p>
        </div>
        <div class="btn" @click="toLink('join')">了解更多 ></div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  methods: {
    toLink (path) {
      this.$router.push({
        path
      })
    }
  },
}
</script>

<style lang="less" scoped>
.joinUs {
  background-color: #8946b9;
  text-align: center;
  .describe {
    width: 724px;
    padding-top: 84px;
    margin: auto;
  }
  @media screen and (max-width: 1200px) {
    .des {
      color: #fff !important;
    }
    .describe {
      width: 100%;
      padding-top: 40px;
      margin: auto;
    }
  }
  @media screen and (min-width: 1200px) {
    .des,
    .btn {
      color: hsla(0, 0%, 100%, 0.4) !important;
    }
    .btn {
      border: 2px solid hsla(0, 0%, 100%, 0.4);
    }
  }
  .til {
    color: #fff !important;
  }
}
</style>
