<template>
  <div class="section product">
    <div class="section_wrapper">
      <div v-if="currentRoute" v-animate-onscroll.repeat="'animate__animated  animate__lightSpeedInLeft'" class="portrait screenShow"><img :src="productPng" /></div>
      <div v-else class="portrait product_portrait"><img :src="productPng" /></div>
      <div class="describe">
        <div class="til">MONTSPACE </div>
        <div class="des" v-if="!title">
          <p>来看看我们的热门产品吧！</p>
        </div>
		<div class="des" v-else>
         {{title}}
        </div>
        <div v-if="showBtn" class="btn" @click="toLink('product')">了解更多 ></div>
      </div>
    </div>
  </div>
</template>

<script>
import productPng from '@/assets/product.png';
export default {
	props: {
		title: {
			type: String,
			default:''
		},
    showBtn: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      productPng,

    }
  },
  computed: {
    currentRoute () {
      return this.$route.path == '/product' ? false : true
    }
  },

  methods: {
    toLink (path) {
      this.$router.push({
        path
      })
    }
  },
}
</script>

<style lang="less" scoped>
.product:hover .product_portrait img {
  animation: fadeIn 1s;
}
.product {
  background: #fcf097;

  .describe {
    position: absolute;
    top: 130px;
    right: 0;
  }
  @media screen and (max-width: 1200px) {
    background: url("../../assets/product2.png");
    background-color: #fdd542;
    background-repeat: no-repeat;
    background-position: 100% 50%;
    background-size: 360px 100%;
    margin-top: 30px;
    .describe {
      width: 100%;
      top: 40px;
    }
    .til {
      text-align: center;
    }
    .portrait {
      display: none;
    }
  }
  @media screen and (min-width: 1200px) {
    .describe {
      width: 652px;
    }
    .til {
      font-size: 34px;
      color: #000;
      text-align: left;
      padding-right: 6%;
    }
    .btn {
      margin-left: 80%;
    }
    .des {
      margin-bottom: 50px;
      text-align: left;
    }
    .portrait {
      position: absolute;
      width: 442px;
      height: 330px;
      left: 10px;
      bottom: 0;
      display: block;
      img {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1200px) {
  }
}
</style>
